import React from "react"

import PageWrapper from "../components/PageWrapper"

import CookiesPolicy from "../features/LegalStuff/CookiesPolicy"

const CookiesPolicyStandalonePage = ({ location }) => {
  return (
    <PageWrapper location={location} isStandalonePage disableCookieConsent>
      <CookiesPolicy />
    </PageWrapper>
  )
}

export default CookiesPolicyStandalonePage
